<template >
  <v-chip :color="options[statusID + 1].color" small :label="label">
    <v-icon left v-text="options[statusID + 1].icon"></v-icon>
    <span class="white--text"> {{ options[statusID + 1].text }}</span>
  </v-chip>
</template>

<script>
export default {
  props: {
    statusID: {
      type: Number,
      default: 1,
    },
    label: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {
        0: { text: this.$t("Common.Cancelled"), color: "grey", icon: "" },
        2: { text: this.$t("Common.Waiting"), color: "info", icon: "" },
        3: { text: this.$t("Common.Paid"), color: "green", icon: "" },
      },
    };
  },
};
</script>
<style scoped>
</style>