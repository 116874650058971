<template>
  <v-container class="pa-0 ma-0 fw">
    <v-toolbar dense flat dark>
      <p class="fl ma-0 pl-5 bold">{{ $t("Common.PackageName") }}</p>
      <v-spacer />
      <p class="fr ma-0 pr-2 bold">{{ $t("Doctor.StaffPerform") }}</p>
      <p class="fr ma-0 pr-2 bold text-right" style="width: 155px">
        {{ $t("Common.OriginalPrice") }}
      </p>
    </v-toolbar>
    <v-skeleton-loader
      height="200px"
      :loading="isLoadingServices"
      type="table-heading, list-item, list-item, list-item"
    >
      <v-treeview dense :items="items" style="background-color: white">
        <template v-slot:prepend="{ item }">
          <v-icon
            :class="{ 'ml-0': !item.isParent, 'mr-1': true }"
            style="margin-top: -4px"
          >
            {{ item.isParent ? "mdi-star-outline" : "mdi-chevron-right" }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:append="{ item }">
          <p class="fr ma-0 h-4 lh-4 tr pr-2" style="width: 155px">
            {{ item.isFree ? "FREE" : item.price || "included" }}
          </p>
          <p class="fr ma-0 h-4 lh-4 tr pr-2" style="width: 200px">
            {{ item.staffPerformName || "Multiple" }}
          </p>
        </template></v-treeview
      ></v-skeleton-loader
    >
    <v-toolbar dense flat dark>
      <p class="fr ma-0 tr bold pr-2 w-100">
        <span class="fr" style="width: 180px">{{ finalPriceString }}</span>
        <span class="fr"> {{ $t("Common.TotalPrice") }}: </span>
      </p>
    </v-toolbar>
  </v-container>
</template>

<script>
import PaymentService from "../../../services/payment";
import { convertPriceString } from "../../../plugins/helper";

export default {
  props: {
    paymentSummaryID: {
      type: Number,
      default: null,
    },
  },
  watch: {
    paymentSummaryID: {
      handler(val) {
        if (!val) return;
        this.renderPaymentDetail(val);
      },
    },
  },
  data: () => ({
    isLoadingServices: true,
    items: [],
    payment: {},
    paymentConfirm: {},
    finalPriceString: "",
  }),
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    async renderPaymentDetail(paymentID) {
      this.isLoadingServices = true;
      var result = await PaymentService.getPayment(paymentID);
      this.isLoadingServices = false;
      if (result.error) {
        this.showError(this.$t("Toast.CannotGetPaymentSummary"));
        return;
      }
      this.payment = result;
      this.finalPriceString = convertPriceString(result.originalPrice);
      this.items = [];
      for (var i = 0; i < this.payment.items.length; i++) {
        var item = this.payment.items[i];
        this.items.push({
          ...item,
          id: `${item.typeID}-${item.targetID}`,
          name: `${item.itemName}`,
          isParent: true,
          isFree: item.isFree,
          price: convertPriceString(item.currentPrice),
        });
        // only add parent items for simplyfication
        item.childrenItems.forEach((child) => {
          this.items.push({
            ...child,
            id: `${child.typeID}-${child.targetID}`,
            isParent: false,
            name: `${child.itemName}`,
            price: "included",
          });
        });
      }
      this.$emit("renderPaymentDetailSuccess", this.payment);
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.h-4 {
  height: 40px !important;
}
.lh-4 {
  line-height: 40px !important;
}
.row-title {
  width: 90px;
  display: inline-block;
  text-align: right;
}
.row-text {
  display: inline-block;
  text-align: left;
}
.fw {
  width: 100%;
  float: left;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px;
}
.v-treeview-node__root {
  padding-right: 0px;
  padding-left: 4px;
}
.success {
  color: yellow;
}
.bold {
  font-weight: 500;
  text-transform: uppercase;
}
.v-application--is-ltr .v-treeview-node__content {
  margin-left: 0px;
}
/* .v-treeview-node__level {
  width: 0px !important;
} */
</style>