import { render, staticRenderFns } from "./PaymentItems.vue?vue&type=template&id=6b6e294c&scoped=true&"
import script from "./PaymentItems.vue?vue&type=script&lang=js&"
export * from "./PaymentItems.vue?vue&type=script&lang=js&"
import style0 from "./PaymentItems.vue?vue&type=style&index=0&id=6b6e294c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b6e294c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VContainer,VIcon,VSkeletonLoader,VSpacer,VToolbar,VTreeview})
