<template>
  <v-container class="PaymentConfirmBox">
    <v-skeleton-loader
      :loading="isSkeletonLoading"
      height="500"
      type="article, card-avatar, actions"
    >
      <v-row class="pl-1 pr-1">
        <v-col cols="5">
          <p class="fr">{{ $t("Common.AdjustmentPrice") }}:</p>
        </v-col>
        <v-col cols="7">
          <v-select
            dense
            :disabled="payment.statusID == 2"
            v-model="payment.adjustmentPriceID"
            :items="adjustmentPrices"
            style="margin-top: -5px"
            @change="
              handleChangeAdjustmentPriceID();
              renderPaymentStrings();
            "
          >
          </v-select>
        </v-col>
      </v-row>
      <v-container class="pa-0 ma-0">
        <p class="lh-4 row-text" style="width: calc(100% - 0px)">
          <v-toolbar dense flat color="grey lighten-4 tr">
            <p class="fr ma-0 tr bold pr-2 w-100">
              <span class="fr" style="width: 160px">{{
                originalPriceString
              }}</span>
              <span class="fr"> {{ $t("Common.TotalPrice") }}: </span>
            </p>
          </v-toolbar>
          <v-toolbar dense flat color="grey lighten-4 tr">
            <p class="fr ma-0 tr bold pr-2 w-100">
              <span class="fr pl-8 mt-1" style="width: 160px"
                ><v-text-field
                  :disabled="payment.statusID == 2"
                  v-model="additionalPriceString"
                  dense
                  reverse
                  @change="renderPaymentStrings"
              /></span>
              <span class="fr"> {{ $t("Common.AdjustmentPrice") }}: </span>
            </p>
          </v-toolbar>
          <v-toolbar dense flat color="grey lighten-4 tr" v-if="promotionText">
            <p class="fr ma-0 tr bold pr-2 w-100">
              <span class="fr" style="width: 160px">{{
                promotionPriceString
              }}</span>
              <span
                class="fr text-wrapper"
                :title="promotionText"
                v-html="promotionText"
              ></span>
            </p>
          </v-toolbar>
          <v-toolbar
            dense
            flat
            color="grey lighten-4 tr"
            v-if="payment.adjustmentPriceID"
          >
            <p class="fr ma-0 tr bold pr-2 w-100">
              <span class="fr" style="width: 160px">{{
                adjustmentPriceString
              }}</span>
              <span class="fr"> {{ $t("Common.AdjustmentPrice") }}: </span>
            </p>
          </v-toolbar>
        </p>
      </v-container>
      <v-divider></v-divider>
      <v-container class="pa-0 ma-0 mt-5">
        <p class="lh-4 row-text" style="width: calc(100% - 0px)">
          <v-toolbar dense flat color="grey lighten-4 tr">
            <p class="fr ma-0 tr bold pr-2 w-100">
              <span class="fr" style="width: 160px">{{
                finalPriceString
              }}</span>
              <span class="fr"> {{ $t("Common.FinalPrice") }}: </span>
            </p>
          </v-toolbar>
          <v-toolbar dense flat color="grey lighten-4 tr">
            <p class="fr ma-0 tr bold pr-2 w-100 pt-4">
              <span class="fr pl-8 mt-1" style="width: 160px"
                ><v-text-field
                  :disabled="payment.statusID == 2"
                  v-model="customerCashAdvanceString"
                  dense
                  reverse
                  @change="renderPaymentStrings"
              /></span>
              <span class="fr"> {{ $t("Common.CashAdvance") }}: </span>
            </p>
          </v-toolbar>
          <v-toolbar dense flat color="grey lighten-4 tr">
            <p class="fr ma-0 tr bold pr-2 w-100">
              <span class="fr" style="width: 160px">{{
                customerCashChangeString
              }}</span>
              <span class="fr"> {{ $t("Common.CashReturn") }}: </span>
            </p>
          </v-toolbar>
        </p>
      </v-container>
      <v-row class="pl-0 pr-0">
        <v-col cols="12">
          <v-textarea
            dense
            filled
            v-model="payment.note"
            :label="$t('Common.Note')"
            style="margin-top: -5px"
            rows="2"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row class="pl-1 pr-1">
        <v-col cols="5">
          <p class="fr">{{ $t("Common.PaymentMethod") }}:</p>
        </v-col>
        <v-col cols="7">
          <v-select
            dense
            v-model="payment.paymentMethod"
            :items="paymentMethodTypes"
            style="margin-top: -5px"
            @change="handleChangePaymentMethod"
          >
          </v-select>
        </v-col>
        <v-col v-show="paymentMethodDescriptionOptions.length" cols="5">
          <p class="fr">{{ $t("Common.MethodDescription") }}:</p>
        </v-col>
        <v-col v-show="paymentMethodDescriptionOptions.length" cols="7">
          <v-combobox
            dense
            v-model="payment.paymentMethodDescription"
            :items="paymentMethodDescriptionOptions"
            style="margin-top: -5px"
          >
          </v-combobox>
        </v-col>
      </v-row>
      <div class="d-flex justify-end pl-3 pr-3 pt-3">
        <v-btn
          width="150px"
          text
          @click="deletePayment"
          v-if="payment.statusID != -1"
        >
          {{ $t("Button.CancelPayment") }}
        </v-btn>
        <v-btn class="pa-4 ml-10" color="primary" @click="confirmPayment">
          {{ payment.statusID == 2 ? $t("Button.Save") : $t("Button.Confirm") }}
          & {{ $t("Button.Print") }}
        </v-btn>
      </div>
    </v-skeleton-loader>
    <DiaglogConfirm ref="confirm" />
  </v-container>
</template>

<script>
import DiaglogConfirm from "../../DialogConfirm";
import PaymentService from "../../../services/payment";
import PriceService from "../../../services/price";
import {
  convertPriceString,
  deconvertPriceString,
} from "../../../plugins/helper";

import { PaymentMethodType } from "@/plugins/constant";

export default {
  props: {
    paymentSummaryID: {
      type: Number,
      default: null,
    },
  },
  watch: {
    paymentSummaryID: {
      handler(val) {
        if (!val) return;
        this.renderPaymentDetail(val);
      },
    },
  },
  components: {
    DiaglogConfirm,
  },
  async created() {
    this.renderListAdjustmentPrices();
  },
  computed: {
    paymentMethodTypes() {
      return Object.keys(PaymentMethodType).map((m) => ({
        text: m,
        value: PaymentMethodType[m],
      }));
    },
  },
  data: () => ({
    isSkeletonLoading: true,
    promotionText: "",
    opens: [],
    payment: {},
    paymentConfirm: {},
    originalPriceString: "0.000 VND",
    promotionPriceString: "0.000 VND",
    finalPriceString: "0.000 VND",
    additionalPriceString: "0.000 VND",
    adjustmentPriceString: "0.000 VND",
    customerCashAdvanceString: "0.000 VND",
    customerCashChangeString: "0.000 VND",
    adjustmentPrices: [],
    paymentMethodDescriptionOptions: [],
  }),
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    renderPaymentMethodDescriptionOptions() {
      this.paymentMethodDescriptionOptions = [];
      if (this.payment.paymentMethod == PaymentMethodType.Card) {
        this.paymentMethodDescriptionOptions = [
          "Credit card",
          "Debit card",
          "Charge card",
          "Prepaid card",
          "Others",
        ];
      }
      if (this.payment.paymentMethod == PaymentMethodType.App) {
        this.paymentMethodDescriptionOptions = [
          "MoMo",
          "Zalo Pay",
          "VNPAY",
          "AirPay",
          "Ngân lượng",
          "VTC Pay",
          "Others",
        ];
      }
    },
    handleChangePaymentMethod() {
      this.renderPaymentMethodDescriptionOptions();
      this.payment.paymentMethodDescription = "";
      if (this.paymentMethodDescriptionOptions.length) {
        this.payment.paymentMethodDescription =
          this.paymentMethodDescriptionOptions[0];
      }
    },
    handleChangeAdjustmentPriceID() {
      var adjustmentID = this.payment.adjustmentPriceID || null;
      this.paymentConfirm.adjustmentPriceID = adjustmentID;
      if (!adjustmentID) {
        this.adjustmentPriceString = "0.000 VND";
      } else {
        var adjustment = this.adjustmentPrices.find(
          (i) => i.id == adjustmentID
        );
        var adjustAmount = adjustment.isPercentage
          ? (adjustment.amount * deconvertPriceString(this.finalPriceString)) /
            100
          : adjustment.amount;
        this.adjustmentPriceString = convertPriceString(adjustAmount);
      }
    },
    async confirmPayment() {
      if (!(await this.$refs.confirm.confirm())) return;
      var { paymentMethod, paymentMethodDescription, note } = this.payment;
      var body = {
        ...this.paymentConfirm,
        paymentMethod,
        paymentMethodDescription,
        note,
      };
      var result = await PaymentService.confirmPayment(
        this.paymentSummaryID,
        body
      );
      if (result.error) {
        this.showError("Cannot confirm this payment. Please try again later!");
        return;
      }
      this.$emit("onConfirm", this.paymentSummaryID);
    },
    async deletePayment() {
      if (!(await this.$refs.confirm.confirm())) return;
      var result = await PaymentService.deletePayment(this.paymentSummaryID);
      if (result.error) {
        this.showError("Cannot delete this payment. Please try again later!");
        return;
      }
      this.$emit("onCancel", this.paymentSummaryID);
    },
    async renderListAdjustmentPrices() {
      var result = await PriceService.getListAdjustments();
      this.isSkeletonLoading = false;
      if (result.error) {
        this.showError(
          "Cannot get list adjustment prices. Please try again later!"
        );
        return;
      }
      this.adjustmentPrices = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
      this.adjustmentPrices.unshift({
        text: "None",
        value: null,
      });
    },
    renderPaymentStrings() {
      var additionalPrice = deconvertPriceString(this.additionalPriceString);
      var promotionPrice = deconvertPriceString(this.promotionPriceString);
      var adjustmentPrice = deconvertPriceString(this.adjustmentPriceString);

      var finalPrice =
        this.payment.originalPrice +
        additionalPrice +
        adjustmentPrice +
        promotionPrice;
      if (finalPrice < 0) finalPrice = 0;
      var customerCashAdvance = deconvertPriceString(
        this.customerCashAdvanceString
      );
      var customerCashChange = customerCashAdvance - finalPrice;

      this.additionalPriceString = convertPriceString(additionalPrice);
      this.finalPriceString = convertPriceString(finalPrice);
      this.customerCashAdvanceString = convertPriceString(customerCashAdvance);
      this.customerCashChangeString = convertPriceString(customerCashChange);

      this.paymentConfirm.customerCashAdvance = customerCashAdvance;
      this.paymentConfirm.additionalPrice = additionalPrice;
    },
    async renderPaymentDetail(paymentID) {
      this.resetForm();

      this.isSkeletonLoading = true;
      var result = await PaymentService.getPayment(paymentID);
      this.isSkeletonLoading = false;
      if (result.error) {
        this.showError("Cannot get payment summary. Please try again later!");
        return;
      }

      this.payment = result;
      this.renderPaymentMethodDescriptionOptions();

      this.paymentConfirm.adjustmentPriceID = result.adjustmentPriceID;
      if (result.promotionCode) {
        this.promotionText = `PROMO: <b>${result.promotionCode}</b>`;
      } else {
        this.promotionText = "";
      }
      this.additionalPriceString = convertPriceString(result.additionalPrice);
      this.adjustmentPriceString = convertPriceString(result.adjustmentPrice);
      this.originalPriceString = convertPriceString(result.originalPrice);
      this.finalPriceString = convertPriceString(result.finalPrice);
      // force minus on promotion
      this.promotionPriceString = convertPriceString(
        result.promotionPrice,
        true,
        true
      );
      this.customerCashChangeString = convertPriceString(
        result.customerCashChange
      );
      this.customerCashAdvanceString = convertPriceString(
        result.customerCashAdvance
      );
      // Remember to
      // render payment confirm objects according to payment strings
      this.renderPaymentStrings();
    },
    resetForm() {
      this.promotionText = "";
      this.opens = [];
      this.payment = {};
      this.paymentConfirm = {};
      this.originalPriceString = "0.000 VND";
      this.promotionPriceString = "0.000 VND";
      this.finalPriceString = "0.000 VND";
      this.additionalPriceString = "0.000 VND";
      this.adjustmentPriceString = "0.000 VND";
      this.customerCashAdvanceString = "0.000 VND";
      this.customerCashChangeString = "0.000 VND";
      this.paymentMethodDescriptionOptions = [];
    },
  },
};
</script>
<style scoped>
.w-100 {
  width: 100%;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.h-4 {
  height: 40px !important;
}
.lh-4 {
  line-height: 40px !important;
}
.row-title {
  width: 90px;
  display: inline-block;
  text-align: right;
}
.row-text {
  display: inline-block;
  text-align: left;
}
.fw {
  width: 100%;
  float: left;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px;
}
.v-treeview-node__root {
  padding-right: 0px;
  padding-left: 4px;
}
.success {
  color: yellow;
}
.bold {
  /* font-weight: 500; */
  text-transform: uppercase;
}
.v-application--is-ltr .v-treeview-node__content {
  margin-left: 0px;
}
</style>
<style lang="scss">
.PaymentConfirmBox {
  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: #f5f5f5;
  }
}
.text-wrapper {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>