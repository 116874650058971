<template>
  <v-card elevation="0" v-if="statusPayment == PaymentStatus.Paid">
    <v-card-title>
      <span>E.HoaDon Info</span>
    </v-card-title>
    <v-card-text>
      <div class="d-block">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <th>
                  <span class="text-subtitle-1">Invoice No:</span>
                </th>
                <td>
                  <span class="black--text">{{ invoiceNo }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="text-subtitle-1">Invoice GUID:</span>
                </th>
                <td>
                  <span class="primary--text">
                    <a :href="`/invoice/view-invoice-detail-by-invoice-guid/${invoiceGUID}`">{{ invoiceGUID }}</a>
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="text-subtitle-1">Parner Invoice ID:</span>
                </th>
                <td>
                  <span class="primary--text d-flex align-center">
                    <a :href="`/invoice/view-invoice-detail-by-invoice-guid/${partnerInvoiceID}`">{{ partnerInvoiceID }}</a>
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="text-subtitle-1">Invoice Serial:</span>
                </th>
                <td>
                  <span class="black--text">{{ invoiceSerial }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="text-subtitle-1">Invoice Form:</span>
                </th>
                <td>
                  <span class="black--text">{{ invoiceForm }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="d-flex justify-end pr-2" v-if="!exportInvoice">
          <a :href="`/invoice/create-new-invoice/${paymentSummaryID}`" class="v-btn v-btn--has-bg theme--light v-size--default primary">Export invoice now</a>
        </div>
      </div>  
    </v-card-text>
  </v-card>
</template>

<script>
import { PaymentStatus } from '@/plugins/constant'
export default {
  name: 'EInvoiceInformationComponent',
  created() {
    
  },
  data: () => {
    return {
      PaymentStatus,
      paymentSummaryID: null,
      statusPayment: null,
      exportInvoice: false,
      invoiceForm: null,
      invoiceGUID: null,
      invoiceNo: null,
      invoiceSerial: null,
      partnerInvoiceID: null
    }
  },
  methods: {
    putInvoiceInfo(item) {
      console.log(item)
      this.paymentSummaryID = item.paymentSummaryID;
      this.statusPayment = item.statusID;
      this.exportInvoice = item.exportInvoice;
      
      this.invoiceForm = item.invoiceForm
      this.invoiceGUID = item.invoiceGUID
      this.invoiceNo = item.invoiceNo
      this.invoiceSerial = item.invoiceSerial
      this.partnerInvoiceID = item.partnerInvoiceID
    },

    showComponentEInvoice() {
      this.statusPayment = this.PaymentStatus.Padi
    }
  }
}
</script>

<style scoped lang="scss">
table {
  th {
    width: 200px;
    font-size: 16px;
  }
  td {
    span {
      font-weight: bold;

    }
  }
}
</style>