<template>
  <v-container class="pa-0 ma-0 fw">
    <v-toolbar dense flat dark>
      <p class="fl ma-0 pl-5 bold">{{ $t('Common.PackageName') }}</p>
      <v-spacer />
      <p class="fr ma-0 pr-2 bold">{{ $t('Common.Price') }}</p>
      <p class="fr ma-0 pr-2 bold text-right" style="width: 130px">{{ $t('Common.Date') }}</p>
      <p class="fr ma-0 pr-2 bold text-right" style="width: 80px">{{ $t('Common.Receipt') }}</p>
    </v-toolbar>
    <v-skeleton-loader
      height="200px"
      :loading="isLoadingServices"
      type="table-heading, list-item, list-item, list-item"
    >
      <v-treeview dense :items="items" style="background-color: white">
        <template v-slot:label="{ item }">
          {{ item.orderItems[0] && item.orderItems[0].itemName }}
        </template>
        <template v-slot:append="{ item }">
          <p class="fr ma-0" style="width: 80px">
            <v-btn
              @click="printPayment(item)"
              style="float: right; margin-top: 8px"
              small
              class="mr-5"
            >
              <v-icon dark> mdi-printer </v-icon>
            </v-btn>
          </p>
          <p class="fr ma-0 h-4 lh-4 tr pr-2" style="width: 130px">
            {{ formatDate(item.dateCreated) }}
          </p>
          <p class="fr ma-0 h-4 lh-4 tr pr-2" style="width: 200px">
            {{ convertPriceString(item.finalPrice) }}
          </p>
        </template>
        <template v-slot:no-data>
          {{ $t('EmptyData.PatientHasNoPayment') }}
        </template>
      </v-treeview>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import moment from "moment";
import PaymentService from "@/services/payment";
import { convertPriceString } from "@/plugins/helper";

export default {
  props: {
    patientID: {
      type: Number,
      default: null,
    },
  },
  watch: {
    patientID: {
      handler(val) {
        if (!val) return;
        this.renderPaymentHistory(val);
      },
    },
  },
  data: () => ({
    isLoadingServices: true,
    items: [],
  }),
  methods: {
    convertPriceString,
    showError(message) {
      this.$toast.error(message);
    },
    formatDate(dateTime) {
      return moment(dateTime).format("DD-MM-YYYY");
    },
    printPayment(payment) {
      // open link with new tab
      var link = `/payment-print?patientID=${payment.patientID}&paymentID=${payment.paymentSummaryID}`;
      window.open(link, "Payment Summary", "width=1100, height=700");
    },
    async renderPaymentHistory(patientID) {
      this.isLoadingServices = true;
      var result = await PaymentService.searchHistoryPayments(patientID);
      this.isLoadingServices = false;
      if (result.error) {
        this.showError(this.$t('Toast.CannotGetPaymentHistory'));
        return;
      }
      this.items = result.items;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.h-4 {
  height: 40px !important;
}
.lh-4 {
  line-height: 40px !important;
}
.row-title {
  width: 90px;
  display: inline-block;
  text-align: right;
}
.row-text {
  display: inline-block;
  text-align: left;
}
.fw {
  width: 100%;
  float: left;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px;
}
.v-treeview-node__root {
  padding-right: 0px;
  padding-left: 4px;
}
.success {
  color: yellow;
}
.bold {
  font-weight: 500;
  text-transform: uppercase;
}
.v-application--is-ltr .v-treeview-node__content {
  margin-left: 0px;
}
/* .v-treeview-node__level {
  width: 0px !important;
} */
</style>