<template>
  <v-row class="pl-3 pr-3">
    <v-col cols="12" md="4" v-show="false">
      <v-select
        v-model="options.clinicID"
        :items="clinics"
        @change="onChangeSearchOptions"
        append-icon="mdi-home-outline"
        :label="$t('Clinic.Clinic')"
        placeholder="My clinic"
        outlined
        dense
      ></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="options.keySearch"
        @keyup.enter="onChangeSearchOptions"
        append-icon="mdi-magnify"
        :label="$t('Button.EnterToSearch')"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="options.statusID"
        :items="statusOptions"
        @change="onChangeSearchOptions"
        append-icon="mdi-home-outline"
        :label="$t('Common.Status')"
        placeholder="All"
        outlined
        dense
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import BrandService from "../../../services/brand";

export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        keySearch: "",
        clinicID: "",
        statusID: "",
      }),
    },
  },
  data() {
    return {
      clinics: [],
      statusOptions: [
        { value: "", text: this.$t("Common.All") },
        { value: 1, text: this.$t("Common.Waiting") },
        { value: 2, text: this.$t("Common.Paid") },
        { value: -1, text: this.$t("Common.Cancelled") },
      ],
    };
  },
  created() {
    this.renderListClinicOptions();
  },
  methods: {
    onChangeSearchOptions() {
      var value = JSON.parse(JSON.stringify(this.options));
      this.$emit("onChangeSearchOptions", value);
    },
    async renderListClinicOptions() {
      this.clinics = [];
      var result = await BrandService.searchBrandClinics(1, 100);
      this.clinics = result.items.map((i) => ({
        text: i.clinicName,
        value: i.clinicID,
      }));
      this.clinics.unshift({
        text: "All clinics",
        value: "",
      });
    },
  },
};
</script>