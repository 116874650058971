var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"pt-0 pl-2 pr-2",attrs:{"light":""}},[_c('PaymentReceipt',{ref:"PaymentReceipt",attrs:{"paymentID":_vm.sumaryID,"patientID":_vm.patientID,"clinicID":_vm.clinicID}}),_c('v-row',[_c('v-col',{staticClass:"d-print-none",attrs:{"cols":"12","lg":"5","xl":"3"}},[_c('v-sheet',{attrs:{"rounded":"lg"}},[_c('v-row',{staticClass:"pl-3 pr-3"},[_c('v-card-title',[_vm._v("Billing Queue")])],1),_c('SearchPayment',{attrs:{"options":_vm.searchOptions},on:{"onChangeSearchOptions":_vm.renderPaymentList}}),_c('div',{staticClass:"d-block pl-2 mt-2"},[_c('v-switch',{attrs:{"label":_vm.viewList ? 'List view' : 'Table view'},model:{value:(_vm.viewList),callback:function ($$v) {_vm.viewList=$$v},expression:"viewList"}})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.isSkeletonLoading,"height":"500","type":"table"}},[_c('v-data-table',{staticClass:"app-custom-table",attrs:{"headers":_vm.list.headers,"items":_vm.list.items,"options":_vm.list.options,"loading":_vm.list.loading,"server-items-length":_vm.list.totals,"disable-sort":"","mobile-breakpoint":"0","item-class":_vm.colorSelectedRow},on:{"update:options":function($event){return _vm.$set(_vm.list, "options", $event)},"update:items-per-page":_vm.renderPaymentList,"update:page":_vm.onChangePagination},scopedSlots:_vm._u([(_vm.viewList)?{key:"item.list",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-block",staticStyle:{"position":"relative"}},[_c('v-list-item',{staticClass:"pr-0"},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"62","color":"primary"}},[(item.avatar)?_c('img',{attrs:{"alt":"Avatar","src":item.avatar}}):_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.getNameInnitial(item.patientName))+" ")])])],1),_c('v-list-item-content',{staticClass:"pl-3"},[_c('v-list-item-title',[_c('a',{staticClass:"patientName",on:{"click":function($event){return _vm.handleClickPaymentItem(item)}}},[_vm._v(_vm._s(item.patientName))])]),_c('v-list-item-subtitle',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-subtitle-2 font-weight-bold"},[_vm._v("Gender: ")]),_c('GenderItem',{attrs:{"genderType":item.genderType}})],1),_c('v-list-item-subtitle',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center mr-1"},[_c('span',{staticClass:"text-subtitle-2 font-weight-bold"},[_vm._v("Time created: ")]),_c('span',{staticClass:"ml-1"},[_c('span',{staticClass:"\n                              text-body-2\n                              font-weight-bold\n                              text-decoration-underline\n                            "},[_vm._v(_vm._s(_vm.moment(item.dateCreated).format("HH:mm:ss")))])])])]),_c('v-list-item-subtitle',{staticClass:"d-flex align-center pt-1"},[_c('span',{staticClass:"text-subtitle-2 font-weight-bold mr-3"},[_vm._v("Payment status: ")]),_c('PaymentStatusChip',{attrs:{"statusID":item.statusID,"label":""}})],1),_c('v-list-item-subtitle',{staticClass:"d-flex align-center pt-1"},[_c('span',{staticClass:"text-subtitle-2 font-weight-bold mr-3"},[_vm._v("E.Invoice status: ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":item.exportInvoice ? 'green' : 'red'}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.exportInvoice ? "Đã xuất hóa đơn" : "Chưa xuất hóa đơn")+" ")])])],1)],1),(
                      _vm.isToday(item.dateCreated) &&
                      item.statusID == _vm.PaymentStatus.Waiting
                    )?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('BlockTime',_vm._g(_vm._b({attrs:{"color":_vm.countWaitingTime(item.dateCreated).color}},'BlockTime',attrs,false),on),[_c('div',{staticStyle:{"width":"100%"},style:({
                              height: _vm.countWaitingTime(item.dateCreated)
                                .height,
                              backgroundColor: _vm.countWaitingTime(
                                item.dateCreated
                              ).color,
                            })})])]}}],null,true)},[_c('span',[_vm._v("Waiting: "+_vm._s(_vm.countWaitingTime(item.dateCreated).minutes)+" minutes")])])],1):_vm._e()],1),_c('v-chip',{staticClass:"font-weight-medium",staticStyle:{"position":"absolute","top":"10px","right":"2px"},attrs:{"label":"","small":"","color":_vm.isToday(item.dateCreated) ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.isToday(item.dateCreated) ? "Today" : _vm.moment(item.dateCreated).format("DD-MM-YYYY"))+" ")])],1)]}}:null,(!_vm.viewList)?{key:"item.patientName",fn:function(ref){
                            var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32px","color":"primary"}},[(item.avatar)?_c('img',{attrs:{"alt":"Avatar","src":item.avatar}}):_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.getNameInnitial(item.patientName))+" ")])]),_c('a',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleClickPaymentItem(item)}}},[_vm._v(_vm._s(item.patientName))])]}}:null,(!_vm.viewList)?{key:"item.genderType",fn:function(ref){
                            var item = ref.item;
return [_c('GenderItem',{attrs:{"genderType":item.genderType}})]}}:null,(!_vm.viewList)?{key:"item.dateCreated",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.moment(item.dateCreated).format("HH:mm")))]),_c('br'),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.moment(item.dateCreated).format("DD-MM")))])]}}:null,(!_vm.viewList)?{key:"item.statusID",fn:function(ref){
                            var item = ref.item;
return [_c('PaymentStatusChip',{attrs:{"statusID":item.statusID}})]}}:null,(!_vm.viewList)?{key:"item.exportInvoice",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":item.exportInvoice ? 'green' : 'red'}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-blank-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.exportInvoice ? "Đã xuất hóa đơn" : "Chưa xuất hóa đơn")+" ")])])],1)]}}:null,{key:"no-data",fn:function(){return [_vm._v(" No billing can be found ")]},proxy:true}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"d-print-none",attrs:{"cols":"12","lg":"7","xl":"9"}},[_c('v-row',[_c('v-col',{staticClass:"d-print-none pt-0",attrs:{"cols":"12","lg":"12","xl":"8"}},[_c('v-sheet',{staticClass:"pb-8",attrs:{"rounded":"lg"}},[_c('NoDataFound',{ref:"NoDataFound",attrs:{"message":'Opps! No billing item can be found.',"default":true}},[_c('v-card-title',[_vm._v(" Billing Information ")]),_c('v-spacer'),(_vm.allowEditServices)?_c('v-btn',{staticClass:"pa-2 pr-4 mr-4",staticStyle:{"float":"right","margin-top":"-50px"},on:{"click":_vm.openReCreateAdmissionForm}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" edit free items ")],1):_vm._e(),_c('PatientInformation',{attrs:{"patientID":_vm.patientID}}),_c('PaymentItems',{ref:"PaymentItems",attrs:{"paymentSummaryID":_vm.sumaryID},on:{"renderPaymentDetailSuccess":_vm.handleRenderPaymentDetailSuccess}}),_c('v-card-title',{staticClass:"justify-center pt-5 pb-5"},[_vm._v(" Payment History ")]),_c('v-spacer'),_c('PaymentHistory',{attrs:{"patientID":_vm.patientID}})],1)],1)],1),_c('v-col',{staticClass:"d-print-none pt-0",attrs:{"cols":"12","lg":"12","xl":"4"}},[_c('v-sheet',{staticClass:"pb-8",attrs:{"rounded":"lg"}},[_c('NoDataFound',{ref:"NoDataFound2",attrs:{"message":'Opps! No billing item can be found.',"default":true}},[_c('v-card-title',[_vm._v(" Payment Confirm ")]),_c('v-spacer'),_c('v-menu',{staticClass:"d-print-none",attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-2 pr-4 mr-4",staticStyle:{"float":"right","margin-top":"-50px"},attrs:{"loading":_vm.isLoadingReceipt},on:{"click":function($event){return _vm.handlePrintPayment('vn')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v(" mdi-printer ")]),_vm._v(" receipt ")],1)]}}])},[_c('v-list',{staticClass:"d-print-none"},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handlePrintPayment('vn')}}},[_vm._v("Viet nam")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.handlePrintPayment('en')}}},[_vm._v("English")])],1)],1)],1),_c('PaymentConfirm',{ref:"PaymentConfirm",attrs:{"paymentSummaryID":_vm.sumaryID},on:{"onConfirm":_vm.handleConfirmSuccess,"onCancel":_vm.renderPaymentList}}),_c('div',{staticClass:"d-flex align-center ma-3 pa-2 justify-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-block",staticStyle:{"border":"1px solid #eaeaea","height":"1px","width":"90%"}})]),_c('EInvoice',{ref:"EInvoiceComponent",staticClass:"mt-2"})],1)],1)],1)],1)],1)],1),_c('ReCreateAdmissionForm',{ref:"ReCreateAdmissionForm",on:{"onSuccess":_vm.handleReCreateAdmissionSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }