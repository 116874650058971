<template >
  <v-main class="pt-0 pl-2 pr-2" light>
    <PaymentReceipt
      ref="PaymentReceipt"
      :paymentID="sumaryID"
      :patientID="patientID"
      :clinicID="clinicID"
    />
    <v-row>
      <v-col cols="12" lg="5" xl="3" class="d-print-none">
        <v-sheet rounded="lg">
          <v-row class="pl-3 pr-3">
            <v-card-title>Billing Queue</v-card-title>
          </v-row>
          <SearchPayment
            :options="searchOptions"
            @onChangeSearchOptions="renderPaymentList"
          />
          <!-- Turn on - off view list -->
          <div class="d-block pl-2 mt-2">
            <v-switch
              v-model="viewList"
              :label="viewList ? 'List view' : 'Table view'"
            ></v-switch>
          </div>
          <v-skeleton-loader
            :loading="isSkeletonLoading"
            height="500"
            type="table"
          >
            <v-data-table
              :headers="list.headers"
              :items="list.items"
              :options.sync="list.options"
              :loading="list.loading"
              :server-items-length="list.totals"
              @update:items-per-page="renderPaymentList"
              @update:page="onChangePagination"
              disable-sort
              class="app-custom-table"
              mobile-breakpoint="0"
              :item-class="colorSelectedRow"
            >
              <!--  List viewing -->
              <template v-slot:[`item.list`]="{ item }" v-if="viewList">
                <div class="d-block" style="position: relative">
                  <v-list-item class="pr-0">
                    <v-list-item-avatar>
                      <v-avatar size="62" color="primary">
                        <img
                          v-if="item.avatar"
                          alt="Avatar"
                          :src="item.avatar"
                        />
                        <span v-else class="white--text">
                          {{ getNameInnitial(item.patientName) }}
                        </span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="pl-3">
                      <v-list-item-title>
                        <a
                          @click="handleClickPaymentItem(item)"
                          class="patientName"
                          >{{ item.patientName }}</a
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle class="d-flex align-center">
                        <span class="text-subtitle-2 font-weight-bold"
                          >Gender:
                        </span>
                        <GenderItem :genderType="item.genderType" />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex align-center">
                        <div class="d-flex align-center mr-1">
                          <span class="text-subtitle-2 font-weight-bold"
                            >Time created:
                          </span>
                          <span class="ml-1">
                            <span
                              class="
                                text-body-2
                                font-weight-bold
                                text-decoration-underline
                              "
                              >{{
                                moment(item.dateCreated).format("HH:mm:ss")
                              }}</span
                            >
                          </span>
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex align-center pt-1">
                        <span class="text-subtitle-2 font-weight-bold mr-3"
                          >Payment status:
                        </span>
                        <PaymentStatusChip :statusID="item.statusID" label />
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex align-center pt-1">
                        <span class="text-subtitle-2 font-weight-bold mr-3"
                          >E.Invoice status:
                        </span>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              v-on="on"
                              v-bind="attrs"
                              :color="item.exportInvoice ? 'green' : 'red'"
                              style="cursor: pointer"
                            >
                              mdi-checkbox-blank-circle
                            </v-icon>
                          </template>
                          <span>
                            {{
                              item.exportInvoice
                                ? "Đã xuất hóa đơn"
                                : "Chưa xuất hóa đơn"
                            }}
                          </span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="
                        isToday(item.dateCreated) &&
                        item.statusID == PaymentStatus.Waiting
                      "
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <BlockTime
                            :color="countWaitingTime(item.dateCreated).color"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <div
                              style="width: 100%"
                              v-bind:style="{
                                height: countWaitingTime(item.dateCreated)
                                  .height,
                                backgroundColor: countWaitingTime(
                                  item.dateCreated
                                ).color,
                              }"
                            ></div>
                          </BlockTime>
                        </template>
                        <span
                          >Waiting:
                          {{ countWaitingTime(item.dateCreated).minutes }}
                          minutes</span
                        >
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>

                  <v-chip
                    label
                    small
                    style="position: absolute; top: 10px; right: 2px"
                    :color="isToday(item.dateCreated) ? 'primary' : ''"
                    class="font-weight-medium"
                  >
                    {{
                      isToday(item.dateCreated)
                        ? "Today"
                        : moment(item.dateCreated).format("DD-MM-YYYY")
                    }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.patientName`]="{ item }" v-if="!viewList">
                <v-avatar size="32px" color="primary">
                  <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
                  <span v-else class="white--text">
                    {{ getNameInnitial(item.patientName) }}
                  </span>
                </v-avatar>
                <a class="ml-2" @click="handleClickPaymentItem(item)">{{
                  item.patientName
                }}</a>
              </template>
              <template v-slot:[`item.genderType`]="{ item }" v-if="!viewList">
                <GenderItem :genderType="item.genderType" />
              </template>
              <template v-slot:[`item.dateCreated`]="{ item }" v-if="!viewList">
                <span style="font-size: 13px">{{
                  moment(item.dateCreated).format("HH:mm")
                }}</span>
                <br />
                <span style="font-size: 11px">{{
                  moment(item.dateCreated).format("DD-MM")
                }}</span>
              </template>
              <template v-slot:[`item.statusID`]="{ item }" v-if="!viewList">
                <PaymentStatusChip :statusID="item.statusID" />
              </template>
              <template
                v-slot:[`item.exportInvoice`]="{ item }"
                v-if="!viewList"
              >
                <div class="d-flex align-center justify-center text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-on="on"
                        v-bind="attrs"
                        :color="item.exportInvoice ? 'green' : 'red'"
                        style="cursor: pointer"
                      >
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </template>
                    <span>
                      {{
                        item.exportInvoice
                          ? "Đã xuất hóa đơn"
                          : "Chưa xuất hóa đơn"
                      }}
                    </span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:no-data> No billing can be found </template>
            </v-data-table>
          </v-skeleton-loader>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="7" xl="9" class="d-print-none">
        <v-row>
          <v-col cols="12" lg="12" xl="8" class="d-print-none pt-0">
            <v-sheet rounded="lg" class="pb-8">
              <NoDataFound
                ref="NoDataFound"
                :message="'Opps! No billing item can be found.'"
                :default="true"
              >
                <v-card-title> Billing Information </v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="allowEditServices"
                  @click="openReCreateAdmissionForm"
                  class="pa-2 pr-4 mr-4"
                  style="float: right; margin-top: -50px"
                >
                  <v-icon dark class="mr-2"> mdi-pencil </v-icon> edit free
                  items
                </v-btn>
                <PatientInformation :patientID="patientID" />
                <PaymentItems
                  ref="PaymentItems"
                  :paymentSummaryID="sumaryID"
                  @renderPaymentDetailSuccess="handleRenderPaymentDetailSuccess"
                />
                <v-card-title class="justify-center pt-5 pb-5">
                  Payment History
                </v-card-title>
                <v-spacer></v-spacer>
                <PaymentHistory :patientID="patientID" />
              </NoDataFound>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="12" xl="4" class="d-print-none pt-0">
            <v-sheet rounded="lg" class="pb-8">
              <NoDataFound
                ref="NoDataFound2"
                :message="'Opps! No billing item can be found.'"
                :default="true"
              >
                <v-card-title> Payment Confirm </v-card-title>
                <v-spacer></v-spacer>
                <!-- show full profile checkbox -->
                <!-- <v-btn
                  @click="handlePrintPayment"
                  :loading="isLoadingReceipt"
                  class="pa-2 pr-4 mr-4"
                  style="float: right; margin-top: -50px"
                >
                  <v-icon dark class="mr-2"> mdi-printer </v-icon> receipt
                </v-btn> -->
                <v-menu class="d-print-none" open-on-hover bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="handlePrintPayment('vn')"
                      :loading="isLoadingReceipt"
                      class="pa-2 pr-4 mr-4"
                      style="float: right; margin-top: -50px"
                    >
                      <v-icon dark class="mr-2"> mdi-printer </v-icon> receipt
                    </v-btn>
                  </template>
                  <v-list class="d-print-none">
                    <v-list-item link>
                      <v-list-item-title @click="handlePrintPayment('vn')"
                        >Viet nam</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="handlePrintPayment('en')"
                        >English</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PaymentConfirm
                  ref="PaymentConfirm"
                  :paymentSummaryID="sumaryID"
                  @onConfirm="handleConfirmSuccess"
                  @onCancel="renderPaymentList"
                />
                <div
                  class="d-flex align-center ma-3 pa-2 justify-center"
                  style="width: 100%"
                >
                  <div
                    class="d-block"
                    style="border: 1px solid #eaeaea; height: 1px; width: 90%"
                  ></div>
                </div>
                <EInvoice ref="EInvoiceComponent" class="mt-2" />
              </NoDataFound>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ReCreateAdmissionForm
      ref="ReCreateAdmissionForm"
      @onSuccess="handleReCreateAdmissionSuccess"
    />
  </v-main>
</template>

<script>
import PatientInformation from "@/components/commonComponents/PatientInfo/PatientInformation";
import PaymentItems from "@/components/commonComponents/Payment/PaymentItems";
import PaymentHistory from "@/components/commonComponents/Payment/PaymentHistory";
import PaymentConfirm from "@/components/commonComponents/Payment/PaymentConfirm";
import SearchPayment from "@/components/commonComponents/Payment/SearchPayment";
import PaymentStatusChip from "@/components/commonComponents/Payment/PaymentStatusChip";
import PaymentReceipt from "@/components/commonComponents/Payment/PaymentReceipt";
import GenderItem from "@/components/commonComponents/Queue/components/GenderItem";
import ReCreateAdmissionForm from "@/components/commonComponents/Admission/ReCreateAdmissionForm";
import EInvoice from "@/components/commonComponents/Payment/EInvoice";
import NoDataFound from "@/components/NoDataFound";
import PaymentService from "@/services/payment";
import moment from "moment";
import styled from "vue-styled-components";
import { getNameInnitial } from "../../plugins/helper";
import { PaymentStatus } from "@/plugins/constant";
// component
const BlockTime = styled(`div`, { color: String })`
  height: 80px;
  width: 10px;
  border: ${(props) =>
    props.color != "" ? `1px solid ${props.color}` : "1px solid"};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 15px;
`;

export default {
  components: {
    SearchPayment,
    PatientInformation,
    PaymentItems,
    PaymentHistory,
    PaymentConfirm,
    GenderItem,
    NoDataFound,
    PaymentStatusChip,
    PaymentReceipt,
    ReCreateAdmissionForm,
    BlockTime,
    EInvoice,
  },
  watch: {
    viewList() {
      if (this.viewList) {
        this.list.headers = [{ text: "List Billing Queue", value: "list" }];
      } else {
        this.list.headers = [
          { text: "Patient Name", value: "patientName" },
          { text: "Gender", value: "genderType" },
          { text: "Time", value: "dateCreated", align: "center" },
          { text: "Status", value: "statusID" },
          { text: "E.Invoice", value: "exportInvoice" },
        ];
      }
    },
  },
  data() {
    return {
      PaymentStatus,
      viewList: false,
      isSkeletonLoading: true,
      isLoadingReceipt: false,
      patientID: null,
      sumaryID: null,
      clinicID: null,
      searchOptions: {
        // default show only payment of user clinic
        clinicID: this.$store.getters.userData.clinicID,
        keySearch: "",
        statusID: "",
      },
      list: {
        headers: [
          // { text: "Clinic", value: "clinicName" },
          // { text: "Booking Code", value: "codeBooking" },

          { text: "Patient Name", value: "patientName" }, // default
          // { text: "DOB", value: "dob" },

          { text: "Gender", value: "genderType" }, // default
          // { text: "Phone", value: "phone" },

          { text: "Time", value: "dateCreated", align: "center" }, // default
          { text: "Status", value: "statusID" }, // default
          { text: "E.Invoice", value: "exportInvoice" }, // default
          // { text: "List", value: "list"}
        ],
        totals: 0,
        items: [],
        loading: true,
        options: {
          itemsPerPage: 12,
        },
      },
      allowEditServices: false,
    };
  },
  async mounted() {
    //set default v-list true
    this.viewList = true;
    await this.renderPaymentList();
    this.isSkeletonLoading = false;
    this.sockets.common.on("EpisodeCompleted", this.onEpisodeCompleted);
  },
  methods: {
    getNameInnitial,
    moment,
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    onEpisodeCompleted() {
      this.renderPaymentList();
      this.showSuccess("New Payment has been submited!");
    },
    handleRenderPaymentDetailSuccess({ statusID }) {
      this.allowEditServices = statusID == 1;
    },
    handleClickPaymentItem(item) {
      this.patientID = item.patientID;
      this.clinicID = item.clinicID;
      this.sumaryID = item.paymentSummaryID;
      this.$refs.NoDataFound.hide();
      this.$refs.NoDataFound2.hide();
      // invoice component
      this.$refs.EInvoiceComponent.putInvoiceInfo(item);
    },
    async handleReCreateAdmissionSuccess(paymentSummaryID) {
      // await this.renderPaymentList();
      this.sumaryID = JSON.parse(JSON.stringify(paymentSummaryID));
      this.$refs.PaymentItems.renderPaymentDetail(paymentSummaryID);
      this.$refs.PaymentConfirm.renderPaymentDetail(paymentSummaryID);
      this.$refs.NoDataFound.hide();
      this.$refs.NoDataFound2.hide();
    },
    async handleConfirmSuccess(paymentID) {
      var item = this.list.items.find((i) => i.paymentSummaryID == paymentID);
      item.statusID = 2;
      this.$forceUpdate();
      await this.handlePrintPayment();
      this.renderPaymentList();
      // open EhoadonComponent
      this.$refs.EInvoiceComponent.showComponentEInvoice();
    },
    async handlePrintPayment(lang = null) {
      this.isLoadingReceipt = true;
      await this.$refs.PaymentReceipt.print(lang);
      this.isLoadingReceipt = false;
    },
    openReCreateAdmissionForm() {
      var billing = this.$refs.PaymentItems.payment;
      if (!billing) return;
      var { bookingID, patientID, items } = billing;
      var orderItems = items.map((i) => ({
        targetID: i.targetID,
        typeID: i.typeID,
      }));
      this.$refs.ReCreateAdmissionForm.renderReCreateAdmissionDetail(
        bookingID,
        patientID,
        orderItems
      );
    },
    async renderPaymentList() {
      this.list.loading = true;

      const { keySearch, clinicID, statusID } = this.searchOptions || {};
      const { page, itemsPerPage } = this.list.options;

      var result = await PaymentService.searchPayments(
        keySearch,
        clinicID,
        statusID,
        page,
        itemsPerPage
      );
      if (result.error) {
        this.showError("Cannot get list bookings. Please try again later!");
        return;
      }
      result.items.forEach((item) => {
        item.phone = item.phone || "N/A";
        item.email = item.email || "N/A";
        item.fullName = item.fullName || "N/A";
        item.timeStart = moment(item.timeStart).format("HH:mm DD-MM");
        item.dob = moment(item.dob).format("DD-MM-YYYY");
      });
      this.list.loading = false;
      this.list.items = result.items;
      this.list.totals = result.totals;
      // Display empty data image
      if (!result.items.length) {
        this.$refs.NoDataFound &&
          this.$refs.NoDataFound.show("Oops! No payment can be found!");
        this.$refs.NoDataFound2 &&
          this.$refs.NoDataFound2.show("Oops! No payment can be found!");
        return;
      }
      // if has data => default load 1st booking item
      else {
        this.$refs.NoDataFound &&
          this.$refs.NoDataFound.show("Select a payment on the left to view!");
        this.$refs.NoDataFound2 &&
          this.$refs.NoDataFound2.show("Select a payment on the left to view!");
      }
    },
    async onChangePagination(value) {
      this.list.options.page = value;
      await this.renderPaymentList();
    },
    colorSelectedRow(item) {
      return item.paymentSummaryID == this.sumaryID ? "grey lighten-2" : "";
    },

    countWaitingTime(time) {
      // default waiting time: 60 minutes;
      let defaultWaiting = 60;
      let now = new moment();
      let timming = new moment(time);
      let duration = moment.duration(now.diff(timming));

      let minutes = duration.minutes();
      let days = duration.days();
      let hours = duration.hours();

      if (days >= 1) {
        return {
          height: "0%",
          color: "#f44336",
          minutes: days * hours * 60 + minutes,
        }; //
      } else {
        if (hours < 1) {
          let percentHeight =
            Math.ceil(((defaultWaiting - minutes) / defaultWaiting) * 100) +
            "%";
          if (minutes <= 10) {
            return { height: percentHeight, color: "#007bff", minutes };
          } else if (minutes > 10 && minutes < 20) {
            return { height: percentHeight, color: "#ffc107", minutes };
          } else {
            return { height: percentHeight, color: "#f44336", minutes };
          }
        } else {
          let totalMinutes = hours * 60 + minutes;
          return { height: "0%", color: "#f44336", minutes: totalMinutes };
        }
      }
    },

    isToday(time) {
      let now = new moment();
      let timming = new moment(time);
      let duration = moment.duration(now.diff(timming));

      let years = duration.years();
      let months = duration.months();
      let days = duration.days();
      return days == 0 && years == 0 && months == 0;
    },
  },
};
</script>
<style scoped>
.patientName {
  font-family: acumin-pro, "Acumin Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-weight: 520;
  font-size: 17px;
}
</style>